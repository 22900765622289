<template>
  <div class="figure__wrapper" :class="{ 'figure__wrapper-details': isDetails }">
    <svg
      :width="defaultSvgParams.width * figureSvgScale"
      :height="defaultSvgParams.height * figureSvgScale"
      :viewBox="`0 0 ${defaultSvgParams.width * figureSvgScale}
         ${defaultSvgParams.height * figureSvgScale}`"
      ref="figure"
      :style="{ transform: `matrix(${zoom},0,0,${zoom},0,0)` }"
    >
      <!-- <defs v-if="!isNav">
        <filter x="-25%" y="-30%" width="1.5" height="1.6" id="text-bg">
          <feFlood flood-color="#fff" result="bg" />
          <feMerge>
            <feMergeNode in="bg" />
            <feMergeNode in="SourceGraphic" />
            <feMorphology in="SourceAlpha" operator="dilate" radius="4" />
          </feMerge>
        </filter>
      </defs>-->
      <ruler
        :x1="0"
        :y1="changeLeftSideHeightUp"
        :x2="defaultSvgParams.width"
        :y2="changeLeftSideHeightUp"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="changeRightSideHeightUp"
        :x2="defaultSvgParams.width"
        :y2="changeRightSideHeightUp"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="changeLeftSideHeightDown"
        :x2="defaultSvgParams.width"
        :y2="changeLeftSideHeightDown"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="changeRightSideHeightDown"
        :x2="defaultSvgParams.width"
        :y2="changeRightSideHeightDown"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthLeft"
        :y1="0"
        :x2="changeWidthLeft"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthRight"
        :y1="0"
        :x2="changeWidthRight"
        :y2="defaultSvgParams.height"
        v-if="!isNav"
      />
      <size-arrow :path="arrowHeight" :is-details="isDetails" color="blue" v-if="!isNav" />
      <size-arrow :path="arrowWidth" :is-details="isDetails" color="green" v-if="!isNav" />
      <size-arrow :path="arrowBiasTop" :is-details="isDetails" color="violet" v-if="!isNav" />
      <size-arrow :path="arrowBiasBottom" :is-details="isDetails" color="orange" v-if="!isNav" />
      <size
        v-if="!isNav"
        :x="changeWidthRight + 30"
        :y="changeRightSideHeightUp + (changeRightSideHeightDown - changeRightSideHeightUp) / 2"
        :text="`E = ${slopeParams.height}`"
        :fill="isDetails ? colors.grey : colors.blue"
      />
      <size
        v-if="!isNav"
        :x="(defaultSvgParams.width * figureSvgScale) / 2"
        :y="changeLeftSideHeightDown + 30"
        :text="`A = ${slopeParams.width}`"
        :fill="isDetails ? colors.grey : colors.green"
      />
      <size
        v-if="!isNav"
        :x="changeWidthLeft - 30"
        :y="(changeLeftSideHeightUp + changeRightSideHeightUp) / 2"
        :text="`B = ${slopeParams.bias1}`"
        :fill="isDetails ? colors.grey : colors.violet"
      />
      <size
        v-if="!isNav"
        :x="changeWidthLeft - 30"
        :y="(changeLeftSideHeightDown + changeRightSideHeightDown) / 2"
        :text="`C = ${slopeParams.bias2}`"
        :fill="isDetails ? colors.grey : colors.orange"
      />
      <defs v-if="isPlate">
        <clipPath :id="`figureMask--sector-${slopeFromState.uid}`">
          <path
            :d="trapezoidMask"
            fill="none"
            :stroke="figureStrokeColor"
            :stroke-width="strokeWidth"
            style="stroke-linecap: round"
          ></path>
          // trapezoid mask
        </clipPath>
      </defs>
      <g
        v-if="isDetails"
        :clip-path="`url(#figureMask--sector-${slopeFromState.uid})`"
        name="rowsForTooltip"
      >
        <!--clip-path="url(#figureMask)"-->
        <g>
          <!--:transform="`translate(0 ${marginBottom})`"-->
          <g v-for="i in rowsCount" :key="i" class="row__overlay">
            <rect
              :x="changeWidthRight - plateWidth * i"
              :y="i % 2 === 0 ? rowStartY - plateHeight / 2 : rowStartY"
              :width="plateWidth - 1"
              :height="i % 2 === 0 ? rowHeight + plateHeight : rowHeight"
              fill="#F6F6F6"
              stroke="#999999"
              stroke-width="1"
              style="shape-rendering: crispEdges"
              @mouseover="showRowInfo(i)"
              @mouseout="hideRowInfo()"
            ></rect>
            <line
              :x1="changeWidthRight - plateWidth * i"
              :y1="
                i % 2 === 0
                  ? rowStartY + plateHeight * n + plateHeight / 2
                  : rowStartY + plateHeight * n
              "
              :x2="changeWidthRight - plateWidth * i + plateWidth - 1"
              :y2="
                i % 2 === 0
                  ? rowStartY + plateHeight * n + plateHeight / 2
                  : rowStartY + plateHeight * n
              "
              stroke="#999999"
              stroke-width="1"
              style="shape-rendering: crispEdges"
              v-for="n in i % 2 === 0 ? itemPerRow : itemPerRow - 1"
              :key="n"
            ></line>
            <row-number :opts="getRowNumberOpts(i)" />
            <!--(plateHeight * n + (plateHeight / 2)) + shift-->
          </g>
        </g>
      </g>
      <size-arrow
        :path="arrowDirection"
        :is-details="isDetails"
        :color="arrowStrokeColor"
        :stroke-width="arrowDirectionStrokeWidth"
        :stroke-dasharray="isDetails ? 20 : 0"
        :opacity="isDetails ? 0.7 : 1"
      />
      <path
        :d="trapezoid"
        class="scheme-circuit"
        fill="none"
        :stroke="figureStrokeColor"
        :stroke-width="strokeWidth"
        style="stroke-linecap: round"
      ></path>
    </svg>
  </div>
</template>

<script>
import {
  getRowNumberFontSize,
  getRowNumberX,
  colors,
  drawSizeArrowVertical,
  drawSizeArrowHorizontal,
  drawArrowDirection
} from '@/utils/drawing'
import RowNumber from '@/components/figure_elements/RowNumber'
import Ruler from '@/components/figure_elements/Ruler'
import SizeArrow from '@/components/figure_elements/SizeArrow'
import Size from '@/components/figure_elements/Size'
import { getMin } from '@/utils'

export default {
  props: {
    isNav: Boolean,
    defaultSvgParams: Object,
    isDetails: Boolean,
    isPlate: Boolean,
    navId: Number,
    zoom: Number,
    rowId: {
      type: Number,
      default: 0
    },
    isForTotalPdf: {
      type: Boolean
    },
    sector: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  components: {
    Size,
    SizeArrow,
    Ruler,
    RowNumber
  },
  methods: {
    getRowNumberOpts(i) {
      return {
        fontSize: getRowNumberFontSize(this.plateWidth),
        x: getRowNumberX(this.changeWidthRight, this.plateWidth, i),
        y: this.arrowDirectionY,
        text: i
      }
    },
    showRowInfo(i) {
      if (this.rowId !== i) {
        this.$emit('updateRowId', i)
      }
    },
    hideRowInfo() {
      if (this.rowId !== 0) {
        this.$emit('updateRowId', 0)
      }
    }
  },
  computed: {
    colors: () => colors,
    // shift() {
    //   return 480 - this.rowHeight
    // },
    slopeFromState() {
      if (this.isForTotalPdf) {
        return this.sector
      } else {
        return (!this.isNav && !this.isDetails) || (this.isDetails && this.isPlate)
          ? this.$store.getters.getSlope(this.slopeId)
          : this.$store.getters.getSlope(this.navId)
      }
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    slopeParams() {
      return this.slopeFromState.slopeParams[0]
    },
    halfPlate() {
      return this.columnsCount % 2 === 0
        ? 0
        : (this.slopeFromState.materialSize.height / 1000 / 2) * this.scale
    },
    rowsCount() {
      return Math.ceil(
        (this.slopeFromState.slopeParams[0].width * 1000) / this.slopeFromState.materialSize.width
      )
    },
    itemPerRow() {
      return (
        Math.ceil(
          this.defaultSvgParams.height /
            ((this.slopeFromState.materialSize.height / 1000) * this.scale)
        ) + 1
      )
    },
    plateHeight() {
      return (this.slopeFromState.materialSize.height / 1000) * this.scale
    },
    plateWidth() {
      return (this.slopeFromState.materialSize.width / 1000) * this.scale
    },
    rowHeight() {
      return this.itemPerRow * this.plateHeight
    },
    rowStartY() {
      return (
        ((this.changeRightSideHeightDown + this.cathet - this.rowHeight) %
          ((this.slopeFromState.materialSize.height / 1000) * this.scale)) -
        (this.slopeFromState.materialSize.height / 1000) * this.scale
      )
    },
    indentationToHeight() {
      return (
        (this.defaultSvgParams.height -
          (this.slopeFromState.slopeParams[0].height +
            this.slopeFromState.slopeParams[0].bias1 +
            this.slopeFromState.slopeParams[0].bias2) *
            this.scale) /
          2 +
        this.slopeFromState.slopeParams[0].bias2 * this.scale
      )
    },
    cathet() {
      return (
        ((this.slopeFromState.slopeParams[0].bias2 *
          (this.slopeFromState.materialSize.width / 1000)) /
          this.slopeFromState.slopeParams[0].width) *
        this.scale
      )
    },
    marginBottom() {
      return (
        (((this.indentationToHeight - this.cathet) %
          ((this.slopeFromState.materialSize.height / 1000) * this.scale)) -
          this.halfPlate -
          (this.slopeFromState.materialSize.height / 1000) * this.scale) *
        -1
      )
    },
    arrowStrokeColor() {
      return this.isNav ? 'nav' : 'grey'
    },
    figureStrokeColor() {
      return this.isNav || this.isDetails ? 'var(--grey-stroke)' : 'var(--black)'
    },
    strokeWidth() {
      return this.isNav || this.isDetails ? 2 : 3
    },
    arrowDirectionStrokeWidth() {
      return this.isNav || this.isDetails ? 2 : 4
    },

    figureSvgScale() {
      return this.isNav ? this.defaultSvgParams.navScale : 1
    },
    arrowDirectionSize() {
      return this.isNav ? 5 : 10
    },
    arrowHeight() {
      const { changeRightSideHeightUp, changeRightSideHeightDown, changeWidthRight } = this
      const x = changeWidthRight + 30
      return drawSizeArrowVertical(x, changeRightSideHeightUp, changeRightSideHeightDown)
    },
    arrowWidth() {
      const { changeLeftSideHeightDown, changeWidthLeft, changeWidthRight } = this
      const y = changeLeftSideHeightDown + 30
      return drawSizeArrowHorizontal(changeWidthLeft, y, changeWidthRight)
    },
    arrowBiasTop() {
      const { changeRightSideHeightUp, changeLeftSideHeightUp, changeWidthLeft } = this
      const x = changeWidthLeft - 30
      return drawSizeArrowVertical(x, changeLeftSideHeightUp, changeRightSideHeightUp)
    },
    arrowBiasBottom() {
      const { changeRightSideHeightDown, changeLeftSideHeightDown, changeWidthLeft } = this
      const x = changeWidthLeft - 30
      return drawSizeArrowVertical(x, changeRightSideHeightDown, changeLeftSideHeightDown)
    },
    arrowDirection() {
      const { arrowDirectionStartX, arrowDirectionEndX, arrowDirectionY, arrowDirectionSize } = this
      return drawArrowDirection(
        arrowDirectionStartX,
        arrowDirectionEndX,
        arrowDirectionY,
        arrowDirectionSize
      )
    },
    trapezoid() {
      const {
        changeWidthLeft,
        changeRightSideHeightDown,
        changeWidthRight,
        changeLeftSideHeightDown,
        changeRightSideHeightUp,
        changeLeftSideHeightUp
      } = this
      return `M${changeWidthLeft},${changeRightSideHeightDown}
      L${changeWidthLeft},${changeLeftSideHeightDown}
      M${changeWidthLeft},${changeLeftSideHeightDown}
      L${changeWidthRight},${changeRightSideHeightDown}
      M${changeWidthRight},${changeRightSideHeightDown}
      L${changeWidthRight},${changeRightSideHeightUp}
      M${changeWidthRight},${changeRightSideHeightUp}
      L${changeWidthLeft},${changeLeftSideHeightUp}
      M${changeWidthLeft},${changeLeftSideHeightUp}
      L${changeWidthLeft},${changeRightSideHeightUp}
      M${changeWidthLeft},${changeRightSideHeightUp}
      L${changeWidthLeft},${changeRightSideHeightDown}
      M${changeWidthLeft},${changeRightSideHeightDown}`
    },
    trapezoidMask() {
      const {
        changeWidthLeft,
        changeRightSideHeightDown,
        changeWidthRight,
        changeLeftSideHeightDown,
        changeRightSideHeightUp,
        changeLeftSideHeightUp
      } = this
      return `M${changeWidthLeft},${changeRightSideHeightDown}
      L${changeWidthLeft},${changeLeftSideHeightDown}
      L${changeWidthRight},${changeRightSideHeightDown}
      L${changeWidthRight},${changeRightSideHeightUp}
      L${changeWidthLeft},${changeLeftSideHeightUp}
      L${changeWidthLeft},${changeRightSideHeightUp}
      L${changeWidthLeft},${changeRightSideHeightDown} Z`
    },
    scaleH() {
      // коэффициент высоты
      return (
        (this.defaultSvgParams.baseHeight * this.figureSvgScale * 100) /
        (this.slopeParams.height + this.slopeParams.bias1 + this.slopeParams.bias2)
      )
    },
    scaleW() {
      // коэффициент ширины
      return (this.defaultSvgParams.baseWidth * this.figureSvgScale * 100) / this.slopeParams.width
    },
    scale() {
      // применяемый коэффициент в зависимости от того какой из коэффициентов больше
      return getMin(this.scaleW, this.scaleH)
    },
    scaledBiasTop() {
      // размер верхнего смещения с применённым коэффициентом
      return this.slopeParams.bias1 * this.scale
    },
    scaledBiasBottom() {
      // размер нижнего смещения с применённым коэффициентом
      return this.slopeParams.bias2 * this.scale
    },
    biasTop() {
      // половина верхнего смещения
      return this.scaledBiasTop / 2
    },
    biasBottom() {
      // половина нижнего смещения
      return this.scaledBiasBottom / 2
    },
    biasDifference() {
      // разница смещений
      return this.biasTop - this.biasBottom
    },
    changeWidthLeft() {
      // изменяем ширину влево
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeWidthRight() {
      // изменяем ширину вправо
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeHeightUp() {
      // изменяем высоту вверх
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 -
        (this.slopeParams.height * this.scale) / 2
      )
    },
    changeHeightDown() {
      // изменяем высоту вниз
      return (
        (this.defaultSvgParams.height * this.figureSvgScale) / 2 +
        (this.slopeParams.height * this.scale) / 2
      )
    },
    changeRightSideHeightUp() {
      // изменяем правую сторону вверх фигуры с учетом смещений
      return this.changeHeightUp + this.biasDifference
    },
    changeRightSideHeightDown() {
      // изменяем правую сторону вниз фигуры с учетом смещений
      return this.changeHeightDown + this.biasDifference
    },
    changeLeftSideHeightUp() {
      // изменяем левую сторону вверх фигуры с учетом смещений
      return this.changeHeightUp - this.scaledBiasTop + this.biasDifference
    },
    changeLeftSideHeightDown() {
      // изменяем левую сторону вниз фигуры с учетом смещений
      return this.changeHeightDown + this.scaledBiasBottom + this.biasDifference
    },
    arrowDirectionStartX() {
      // изменяем ширину направляющей стрелки влево
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        ((this.slopeParams.width / 2) * this.scale) / 2
      )
    },
    arrowDirectionEndX() {
      // изменяем ширину напрвляющей стрелки вправо
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        ((this.slopeParams.width / 2) * this.scale) / 2
      )
    },
    arrowDirectionY() {
      // изменяем положение напрвляющей стрелки по высоте
      return (this.changeRightSideHeightDown + this.changeRightSideHeightUp) / 2
    },
    arrowDirectionRotationDegree() {
      // высчитываем угол поворота направляющей
      // стрелки что бы она была перпендикулярна гипотенузе
      return (Math.atan(1 / this.slopeParams.ratio) * 180) / Math.PI - 90
    }
  },
  mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass"></style>
